import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["minKeepersWrapper", "maxKeepersWrapper", "keeperType"];

  connect() {
    // Get all radio inputs within the targeted radio group
    const radioInputs = this.keeperTypeTarget.querySelectorAll(
      'input[type="radio"]',
    );

    // Add change event listeners
    radioInputs.forEach((input) => {
      input.addEventListener("change", this.toggleKeeperInputs.bind(this));
    });

    // Initial check on load
    this.toggleKeeperInputs();
  }

  toggleKeeperInputs() {
    const selectedValue = this.keeperTypeTarget.querySelector(
      'input[type="radio"]:checked',
    ).value;
    const isKeeper = selectedValue === "1"; // Keeper type has value "1"

    // Toggle visibility using Tailwind classes
    if (isKeeper) {
      this.minKeepersWrapperTarget.classList.remove("hidden");
      this.maxKeepersWrapperTarget.classList.remove("hidden");
    } else {
      this.minKeepersWrapperTarget.classList.add("hidden");
      this.maxKeepersWrapperTarget.classList.add("hidden");
    }
  }
}
