// controllers/move_limits_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["moveLimitRound", "moveLimitSeason", "tradeDeadlineAt"];

  connect() {
    // Initialize visibility on connect
    this.toggleAddLimits();
    this.toggleTradeDeadline();
  }

  toggleAddLimits() {
    const inseasonAddsCheckbox = this.element.querySelector(
      "#move_limits_form_inseasonAdds",
    );
    const isChecked = inseasonAddsCheckbox.checked;

    // Get parent divs to hide entire form rows
    const roundParent = this.moveLimitRoundTarget.closest(".mb-3");
    const seasonParent = this.moveLimitSeasonTarget.closest(".mb-3");

    roundParent.style.display = isChecked ? "block" : "none";
    seasonParent.style.display = isChecked ? "block" : "none";
  }

  toggleTradeDeadline() {
    const isTradeDeadlineCheckbox = this.element.querySelector(
      "#move_limits_form_tradeDeadline",
    );
    const isChecked = isTradeDeadlineCheckbox.checked;

    // Get parent div to hide entire form row
    const deadlineParent = this.tradeDeadlineAtTarget.closest(".mb-3");
    deadlineParent.style.display = isChecked ? "block" : "none";
  }
}
