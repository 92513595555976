import { Controller } from "@hotwired/stimulus";
import useLineupStore from "../react/draft/lib/store/LineupStore";
import { getFormationPosByAbbr } from "../react/global/lib/utils/formationPositions";

export default class extends Controller {
  static targets = ["form"];
  static values = {
    lineup: Object,
    formation: Object,
  };

  connect() {
    // When the controller connects, show the alert with the data
    this.showLineupData();

    this.element.addEventListener(
      "lineupEvent",
      this.handleLineupEvent.bind(this),
    );

    const turboFrame = document.getElementById("lineup-frame");
    if (turboFrame) {
      turboFrame.addEventListener(
        "turbo:frame-load",
        this.handleFrameLoad.bind(this),
      );
    }
  }

  disconnect() {
    super.disconnect();

    this.clearStores();
  }

  showLineupData() {
    // Access the data through the values
    const props = {
      lineup: this.lineupValue,
      formation: this.formationValue,
    };
    console.log(props);

    const players = this.lineupValue.players;

    useLineupStore.getState().setPlayers(players);
    useLineupStore.getState().setFormation(this.formationValue);
    useLineupStore.getState().setBonusEligible(this.lineupValue.eligible);
  }

  async handleLineupEvent(event) {
    // console.log("Lineup event detected", event.detail);

    const selectedPlayerId = useLineupStore.getState().selectedPlayerId;
    const swapPlayerId = useLineupStore.getState().selectedSwapPlayerId;
    const swapPositionAbbr = useLineupStore.getState().selectedSwapPositionAbbr;
    const swapPositionType = useLineupStore.getState().selectedSwapPositionType;
    const swapListOrder = useLineupStore.getState().selectedSwapListOrder;
    //
    // console.log(
    //   "Selected player:",
    //   selectedPlayerId,
    //   "Swap player:",
    //   swapPlayerId,
    // );

    const players = this.lineupValue.players;
    const selectedPlayer = players.find(
      (player) => player.id === selectedPlayerId,
    );
    const originalFormationPos = getFormationPosByAbbr(
      selectedPlayer.selectedPos,
    );

    const swapPlayer = players.find((player) => player.id === swapPlayerId);

    // console.log("Moved to ", swapPositionAbbr, swapPositionType, swapPlayer);

    const lineupMap = players.map((player) => {
      if (player.id === selectedPlayerId) {
        // Set player to target position
        return {
          id: player.id,
          pos: swapPositionAbbr,
          type: swapPositionType,
          listOrder: swapListOrder,
        };
      } else if (player.id === swapPlayerId) {
        // If player was in target pos, try move them to original pos
        if (
          player.playerPos.some((p) =>
            originalFormationPos.eligiblePlayerPos.includes(p),
          )
        ) {
          // console.log("can swap");
          return {
            id: player.id,
            pos: selectedPlayer.selectedPos,
            type: selectedPlayer.type,
            listOrder: selectedPlayer.listOrder,
          };
        } else {
          // console.log("cant swap, put on bench");
          // Move to bench
          return {
            id: player.id,
            pos: "BN",
            type: "bench",
            listOrder: 99,
          };
        }
      }

      return {
        id: player.id,
        pos: player.selectedPos,
        type: player.type,
        listOrder: player.listOrder,
      };
    });

    // console.log("Updated players:", lineupMap);
    // await new Promise((resolve) => setTimeout(resolve, 500));

    this.submitForm(lineupMap);
  }

  submitForm(lineupMap) {
    // Update the existing hidden input instead of creating a new one
    const hiddenField = this.formTarget.querySelector(
      "#lineup_json_form_newLineup",
    );
    hiddenField.value = JSON.stringify(lineupMap);

    this.formTarget.requestSubmit();
  }

  handleFrameLoad(event) {
    // After form frame loads after submit, dispatch a custom event to close the bottom sheet
    const closeSheetEvent = new CustomEvent("closeSheet", { detail: {} });
    document.dispatchEvent(closeSheetEvent);

    this.clearStores();
  }

  clearStores() {
    useLineupStore.getState().setSelectedPlayerId(null);
    useLineupStore.getState().setSelectedSwapPlayerId(null);
    useLineupStore.getState().setSelectedSwapPositionAbbr(null);
    useLineupStore.getState().setSelectedSwapPositionType(null);
    useLineupStore.getState().setSelectedSwapListOrder(null);
    useLineupStore.getState().setDropPlayerIds([]);
    useLineupStore.getState().setDropPositionAbbrs([]);
  }
}
