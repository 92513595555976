import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["earlyByes", "midByes", "byeMitigation", "scorers"];

  connect() {
    if (!this.hasScorersTarget) return;

    if (this.hasEarlyByesTarget) {
      this.earlyByesTarget.addEventListener(
        "change",
        this.toggleScorersVisibility.bind(this),
      );
      this.midByesTarget.addEventListener(
        "change",
        this.toggleScorersVisibility.bind(this),
      );
    }

    if (this.hasByeMitigationTarget) {
      this.byeMitigationTarget
        .querySelectorAll('input[type="radio"]')
        .forEach((radio) => {
          radio.addEventListener(
            "change",
            this.toggleScorersVisibility.bind(this),
          );
        });
    }

    this.toggleScorersVisibility();
  }

  toggleScorersVisibility() {
    if (!this.hasScorersTarget) return;

    let shouldShow = false;

    if (this.hasEarlyByesTarget) {
      shouldShow =
        this.earlyByesTarget.value === "1" || this.midByesTarget.value === "1";
    } else if (this.hasByeMitigationTarget) {
      const selectedRadio = this.byeMitigationTarget.querySelector(
        'input[type="radio"]:checked',
      );
      shouldShow = selectedRadio?.value === "1";
    }

    const scorersContainer = this.scorersTarget.closest(".mb-3");
    scorersContainer.classList.toggle("hidden", !shouldShow);
  }
}
