import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["days", "hours", "minutes", "seconds"];
  static values = {
    date: String,
  };

  connect() {
    this.updateCountdown();
    this.timer = setInterval(() => {
      this.updateCountdown();
    }, 1000);
  }

  disconnect() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  updateCountdown() {
    const targetDate = new Date(this.dateValue).getTime();
    const now = new Date().getTime();
    const difference = targetDate - now;

    if (difference > 0) {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
      );
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      this.daysTarget.textContent = days;
      this.hoursTarget.textContent = hours;
      this.minutesTarget.textContent = minutes;
      this.secondsTarget.textContent = seconds;
    }
  }
}
